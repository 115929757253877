<template>
    <div>
        <div
            class="align-items-center border-grey qu-fc-color curser-pointer"
            v-b-modal.export-response-questions
            :class="{ 'disabled': questions.length < 1 }"
            :aria-disabled="questions.length < 1"
            :style="{ pointerEvents: questions.length < 1 ? 'none' : 'auto' }"
            >
            <span class="text-nowrap">Export Output</span>
         </div>
       
         <b-modal id="export-response-questions" hide-footer size="lg" centered title="Questions and Options" @hidden="resetModal">
        <div class="modal_content">
        <div v-for="(question, index) in questions" :key="index">
          <h3>{{ index + 1 }}. {{ question.title }}</h3>
          <ol>
            <li v-for="(option) in question.options" >
              {{ option.title }} 
            </li>
          </ol>
        </div>

        <div class="modal-footer">
          <b-button @click="copyToClipboard"  variant="primary">
            Copy
          </b-button>
          <b-button @click="closeModal" variant="danger">
          Close
        </b-button>
          
        </div>
      </div>
    </b-modal>

    </div>
</template>

<script>
import {
    BButton,
    BAlert,
    BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BButton,
        BAlert,
        BSpinner,
        ToastificationContent,
    },
    data() {
        return {
            questionText: "",
            alertMessageToast: "",
        };
    },
    computed: {
        
    },
    
    methods: {
        getQuestions() {
            console.log('this.questions',this.questions);
        },
        
        resetModal() {
            this.$emit("resetGptModel");
            this.questionText = "";
        },

        formatQuestions() {
        return this.questions.map((question, qIndex) => {
            const optionsText = question.options.map((option, oIndex) => {
            return `${String.fromCharCode(97 + oIndex)}) ${option.title}`;
            }).join('\n');
            return `${qIndex + 1}. ${question.title}\n${optionsText}`;
        }).join('\n\n');
    },
    copyToClipboard() {
      const textToCopy = this.formatQuestions();
      navigator.clipboard.writeText(textToCopy).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Copied to clipboard!",
            variant: "success",
          }
        });
      }).catch(err => {
        console.error('Failed to copy text: ', err);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Failed to copy text.",
            variant: "danger",
          }
        });
      });
    },
    showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        closeModal() {
            this.$bvModal.hide('export-response-questions');
            },
        
        resetModal() {
            this.$emit("resetGptModel");
            this.questionText = "";
        },

    },
    props: ["questions"],
};
</script>

<style>
#add-questions-modal .card-body {
    padding: 0px;
}

.curser-pointer {
    cursor: pointer;
}
ol {
      list-style-type: lower-alpha; 
    }
</style>
